import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Acerca de mí`}</h2>
    <p>{`Soy Isaac, productor panameño con más de 7 años trabajando en diferentes áreas de producción y publicidad. Conocimientos en venta personal, marketing digital, BTL, producción de TV, difusión, edición y fotografía.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      